import React from 'react'

import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts'

import { IoBagHandle, IoPieChart, IoPeople, IoCart } from 'react-icons/io5'
import { LuLeafyGreen } from 'react-icons/lu'
import { FaUserFriends } from 'react-icons/fa'

function Insight() {
    const vendorCardInfoItems = [
        { number: '10%', itemName: 'Rewards Redemption' },
        { number: '15%', itemName: 'Customer Engagement' }
    ]
    const leaderCardInfoItems = [
        { number: '25%', itemName: 'Rewards Redemption' },
        { number: '35%', itemName: 'Customer Engagement' }
    ]

    return (
        <div className="flex gap-8">
            <BoxWrapper>
                <div className="mx-2">
                    <div className="flex flex-col justify-center items-center pb-4">
                        <span className="text-xl font-bold">You</span>
                    </div>
                    {vendorCardInfoItems.map((item, index) => (
                        <div className="pb-4" key={index}>
                            <InsideCardInfoItem number={item.number} itemName={item.itemName} />
                        </div>
                    ))}
                </div>
                <div className="mx-2">
                    <div className="flex flex-col justify-center items-center pb-4">
                        <span className="text-xl font-bold">Leaders</span>
                    </div>
                    {leaderCardInfoItems.map((item, index) => (
                        <div className="pb-4" key={index}>
                            <InsideCardInfoItem number={item.number} itemName={item.itemName} fontColorClass="green" />
                        </div>
                    ))}
                </div>
            </BoxWrapper>

            <BoxWrapper>
                <div className="mx-2">
                    <div className="flex flex-col justify-center items-center pb-4">
                        <span className="text-lg text-center ">Most Redeemed Rewards in Your Sector</span>
                    </div>
                    {['Buy one get one free', 'Discounts', 'Free Gifts'].map((item, index) => (
                        <div className="pb-2">
                            {index + 1}. <span className="font-light text-gray-600">{item}</span>
                        </div>
                    ))}
                </div>
            </BoxWrapper>

            <BoxWrapper>
                <div className="mx-2">
                    <div className="flex flex-col justify-center items-center pb-4">
                        <span className="text-lg text-center ">Most Redeemed Rewards in Your Sector</span>
                    </div>
                    {[
                        'Cross Promption Opportunities',
                        'Reward Offering',
                        'Trending Products that are not part of your offering'
                    ].map((item, index) => (
                        <div className="pb-2">
                            {index + 1}.{' '}
                            <span className="font-light text-gray-600">
                                {item} <a href="#"> (see Insights)</a>
                            </span>{' '}
                        </div>
                    ))}
                </div>
            </BoxWrapper>
        </div>
    )
}

function InsideCardInfoItem({ number, itemName, fontColorClass = 'gray' }) {
    return (
        <div className="flex flex-col justify-center items-center">
            <strong className={`text-xl text-${fontColorClass}-600 font-bold`}>{number}</strong>
            <strong className={`text-xs text-gray-400 font-semibold`}>{itemName}</strong>
        </div>
    )
}

function BoxWrapper({ children }) {
    return (
        <div className="bg-white rounded-sm p-4 flex-1 border border-gray-200 flex items-center justify-around">
            {children}
        </div>
    )
}

function SmallNumberSummary({ title, number, icon }) {
    return (
        <BoxWrapper>
            {icon}
            <div className="pl-4 flex flex-col justify-center items-center">
                <span className="text text-gray-500 font-light">{title}</span>
                <div className="flex items-center pt-2">
                    <strong className="text-xl text-gray-700 font-semibold">{number}</strong>
                </div>
            </div>
        </BoxWrapper>
    )
}

const RADIAN = Math.PI / 180

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.65
    // const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    )
}

function BuildPieChart({ title, data }) {
    return (
        <div className="w-[20rem] h-[22rem] bg-white p-4 rounded-sm border border-gray-200 flex flex-col">
            <strong className="text-gray-700 font-lg">{title}</strong>
            <div className="mt-3 w-full flex-1 text-xs">
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={400} height={300}>
                        <Pie
                            data={data}
                            cx="50%"
                            cy="45%"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={105}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {data.map((item, index) => (
                                <Cell key={`cell-${index}`} fill={item.color} />
                            ))}
                        </Pie>
                        <Legend />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}

export default function Dashboard() {
    const icons = [
        <div className={`rounded-full h-12 w-12 flex items-center justify-center bg-sky-500`}>
            <IoBagHandle className="text-2xl text-white" />
        </div>,
        <div className={`rounded-full h-12 w-12 flex items-center justify-center bg-green-500`}>
            <LuLeafyGreen className="text-2xl text-white" />
        </div>,
        <div className={`rounded-full h-12 w-12 flex items-center justify-center bg-yellow-500`}>
            <FaUserFriends className="text-2xl text-white" />
        </div>
    ]

    const colors = [
        {
            shade: 'Forest Green',
            hex: '#228A22'
        },
        {
            shade: 'Mint Green',
            hex: '#58AA51'
        },
        {
            shade: 'Olive Green',
            hex: '#80A000'
        },
        {
            shade: 'Lime Green',
            hex: '#32CD32'
        },
        {
            shade: 'Sage Green',
            hex: '#009F11'
        }
    ];


    const piechartDataItems = [
        {
            title: 'Customer Spend Habit (Category)',
            data: [
                { name: 'Others', value: 15, color: colors[0].hex },
                { name: 'Clothing', value: 10, color: colors[1].hex },
                { name: 'Food and Drinks', value: 30, color: colors[2].hex },
                { name: 'Entertainment', value: 5, color: colors[3].hex },
                { name: 'Personal Care', value: 40, color: colors[4].hex }
            ]
        },
        {
            title: 'Customer Demographic (Age)',
            data: [
                { name: '18-25', value: 45, color: colors[0].hex},
                { name: '26-35', value: 35, color: colors[1].hex},
                { name: '36-45', value: 10, color: colors[2].hex},
                { name: '46-55', value: 5, color: colors[3].hex},
                { name: '56+', value: 5, color: colors[4].hex }
            ]
        },
        {
            title: 'Shared Customer Base with Your Key Competitors',
            data: [
                { name: 'Shared', value: 30, color: colors[0].hex },
                { name: 'Unique', value: 70, color: colors[1].hex }
            ]
        }
    ]

    return (
        <div className="flex flex-col gap-4">
            <Insight />
            <div className="flex flex-row gap-4">
                <SmallNumberSummary title="Monthly Average Spending" number="$1250" icon={icons[0]} />
                <SmallNumberSummary title="Monthly Green Spending" number="$250" icon={icons[1]} />
                <SmallNumberSummary title="Monthly Spending in Your Sector" number="$50" icon={icons[2]} />
            </div>
            <div className="flex flex-row gap-4 w-full">
                {piechartDataItems.map((item, index) => (
                    <BuildPieChart title={item.title} data={item.data} />
                ))}
            </div>
        </div>
    )
}
